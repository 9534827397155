.programs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 0 2rem;
}

.program-header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}